<template>
  <b-container class="wallet-history">
    <div class="box-member">
      <div class="title">Wallet History</div>
      <div class="type-history" v-if="false">
        <b-form-select
          v-model.trim="typeHistory"
          required
          id="CoinDeposit"
          :options="ListTypeHistory"
          value-field="value"
          text-field="text"
        ></b-form-select>
      </div>
      <!-- v-if="typeHistory === 'Ctrader'" -->
      <div class="box-table px-2" v-if="typeHistory === 'Ctrader'">
        <div class="table-search" v-if="false">
          <b-form-input
            size="sm m-1"
            placeholder="User ID"
            v-model.trim="search.userID"
          ></b-form-input>
          <b-form-input
            size="sm m-1"
            placeholder="Email"
            v-model.trim="search.userEmail"
          ></b-form-input>
          <b-form-input size="sm m-1" placeholder="F.." v-model.trim="search.userF"></b-form-input>
          <b-button variant="outline-success" class="btn-egg btn-sm m-1" @click="onSearch">
            Search
          </b-button>
          <b-button variant="warning" class="btn-sm m-1" @click="onSearch('reset')">
            Reset
          </b-button>
        </div>
        <div class="table-reponsive">
          <b-table
            :striped="true"
            :hover="true"
            :items="Wallet.list"
            :fields="fieldsMain"
            show-empty
            v-if="Actions"
          >
            <template #empty="">
              <h5 class="text-center p-2">No Data</h5>
            </template>
            <template #cell(Money_Time)="data">
              <p class="text-center mb-0">
                {{ getDateTime(data.item.Money_Time) }}
              </p>
            </template>
            <template #cell(Money_USDT)="data">
              <p
                class="text-right mb-0"
                :class="parseFloat(data.item.Money_USDT) > 0 ? 'color-green' : 'color-red'"
              >
                {{ data.item.Money_USDT }}
              </p>
            </template>

            <template #cell(Money_USDTFee)="data">
              <p class="text-right mb-0">
                {{ data.item.Money_USDTFee }}
              </p>
            </template>
            <template #cell(Money_Rate)="data">
              <p class="text-right mb-0">
                {{ data.item.Money_Rate }}
              </p>
            </template>
            <template #cell(Money_Currency)="data">
              <p class="text-center mb-0">
                {{ getSymbol(data.item.Money_Currency) }}
              </p>
            </template>
            <template #cell(Money_MoneyAction)="data">
              <p class="text-center mb-0">
                {{
                  getAction(data.item.Money_MoneyAction)
                    ? getAction(data.item.Money_MoneyAction).MoneyAction_Name
                    : ''
                }}
              </p>
            </template>
            <template #cell(Money_Comment)="data">
              <p class="text-left mb-0">
                {{ data.item.Money_Comment }}
              </p>
            </template>
            <template #cell(Money_MoneyStatus)="data">
              <p
                class="mb-0"
                :class="
                  getClass(
                    data.item.Money_MoneyStatus,
                    data.item.Money_Confirm,
                    data.item.Money_MoneyAction,
                  ).class
                "
              >
                {{
                  getClass(
                    data.item.Money_MoneyStatus,
                    data.item.Money_Confirm,
                    data.item.Money_MoneyAction,
                  ).text
                }}
              </p>
            </template>
          </b-table>
        </div>
        <div class="table-paginate">
          <Paginate @current="onChangePage" :totalPages="Wallet.total" />
        </div>
      </div>
      <!-- v-if="typeHistory === 'MT5'" -->
      <div class="box-table px-2" v-if="typeHistory === 'MT5'">
        <div class="table-search" v-if="false">
          <b-form-input
            size="sm m-1"
            placeholder="User ID"
            v-model.trim="search.userID"
          ></b-form-input>
          <b-form-input
            size="sm m-1"
            placeholder="Email"
            v-model.trim="search.userEmail"
          ></b-form-input>
          <b-form-input size="sm m-1" placeholder="F.." v-model.trim="search.userF"></b-form-input>
          <b-button variant="outline-success" class="btn-egg btn-sm m-1" @click="onSearch">
            Search
          </b-button>
          <b-button variant="warning" class="btn-sm m-1" @click="onSearch('reset')">
            Reset
          </b-button>
        </div>
        <div class="table-reponsive">
          <b-table
            :striped="true"
            :hover="true"
            :items="HistoryMT5.list"
            :fields="fieldsMain"
            show-empty
            v-if="Actions"
          >
            <template #empty="">
              <h5 class="text-center p-2">No Data</h5>
            </template>
            <template #cell(Money_Time)="data">
              <p class="text-center mb-0">
                {{ getDateTime(data.item.Money_Time) }}ádas
              </p>
            </template>
            <template #cell(Money_USDT)="data">
              <p
                class="text-right mb-0"
                :class="parseFloat(data.item.Money_USDT) > 0 ? 'color-green' : 'color-red'"
              >
                {{ data.item.Money_USDT }}
              </p>
            </template>

            <template #cell(Money_USDTFee)="data">
              <p class="text-right mb-0">
                {{ data.item.Money_USDTFee }}
              </p>
            </template>
            <template #cell(Money_Rate)="data">
              <p class="text-right mb-0">
                {{ data.item.Money_Rate }}
              </p>
            </template>
            <template #cell(Money_Currency)="data">
              <p class="text-center mb-0">
                {{ getSymbol(data.item.Money_Currency) }}
              </p>
            </template>
            <template #cell(Money_MoneyAction)="data">
              <p class="text-center mb-0">
                {{
                  getAction(data.item.Money_MoneyAction)
                    ? getAction(data.item.Money_MoneyAction).MoneyAction_Name
                    : ''
                }}
              </p>
            </template>
            <template #cell(Money_Comment)="data">
              <p class="text-left mb-0">
                {{ data.item.Money_Comment }}
              </p>
            </template>
            <template #cell(Money_MoneyStatus)="data">
              <p
                class="mb-0"
                :class="
                  getClass(
                    data.item.Money_MoneyStatus,
                    data.item.Money_Confirm,
                    data.item.Money_MoneyAction,
                  ).class
                "
              >
                {{
                  getClass(
                    data.item.Money_MoneyStatus,
                    data.item.Money_Confirm,
                    data.item.Money_MoneyAction,
                  ).text
                }}
              </p>
            </template>
          </b-table>
        </div>
        <div class="table-paginate">
          <Paginate @current="onChangePage" :totalPages="HistoryMT5.total" />
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import filter from 'lodash/filter';

import moment from 'moment';
import Paginate from '@/components/shared/Paginate.vue';

export default {
  components: { Paginate },
  data() {
    return {
      typeHistory: 'Ctrader',
      search: {
        userID: '',
        userEmail: '',
        userF: '',
        page: 1,
      },
      fields: [
        {
          key: 'Money_Time',
          sortable: false,
          label: 'Time',
        },
        {
          key: 'Money_CurrentAmount',
          sortable: false,
          label: 'Amount',
        },
        {
          key: 'f',
          label: 'Ref',
          sortable: false,
        },
        {
          key: 'registeredDatetime',
          label: 'Registration Date',
        },
      ],
      currentPage: 1,
      totalPages: 10,
    };
  },
  computed: {
    ...mapGetters({
      UserInfo: 'user/UserInfo',
      Wallet: 'history/Wallet',
      Coin: 'wallet/Coin',
      Actions: 'wallet/Actions',
      HistoryMT5: 'history/HistoryMT5',
    }),
    fieldsMain() {
      return [
        {
          key: 'Money_Time',
          label: 'Time',
          sortable: true,
          class: 'text-center text-middle',
        },
        {
          key: 'Money_USDT',
          label: 'Amount',
          sortable: true,
          class: 'text-center text-middle',
        },
        {
          key: 'Money_USDTFee',
          label: 'Fee',
          sortable: true,
          class: 'text-center text-middle',
        },
        {
          key: 'Money_Rate',
          label: 'Rate',
          sortable: true,
          class: 'text-center text-middle',
        },
        {
          key: 'Money_Currency',
          label: 'Currency',
          sortable: true,
          class: 'text-center text-middle',
        },
        {
          key: 'Money_MoneyAction',
          label: 'Action',
          sortable: true,
          class: 'text-center text-middle',
        },
        {
          key: 'Money_TXID',
          label: 'Txid',
          sortable: true,
          class: 'text-center text-middle',
        },
        {
          key: 'Money_Comment',
          label: 'Comment',
          sortable: true,
          class: 'text-center text-middle',
        },
        {
          key: 'Money_MoneyStatus',
          label: 'Status',
          sortable: true,
          class: 'text-center text-middle',
        },
      ];
    },
    ListTypeHistory() {
      return [
        { value: 'Ctrader', text: 'Ctrader' },
        { value: 'MT5', text: 'MT5' },
      ];
    },
  },
  methods: {
    getClass(status, confirm, action) {
      // console.log(status, confirm, action);
      if (action === 2) {
        if (confirm === 1) {
          return {
            class: 'text-success',
            text: 'Confirmed',
          };
        }
        if (confirm === -1) {
          return {
            class: 'text-danger',
            text: 'Canceled',
          };
        }
        return {
          class: 'text-warning',
          text: 'Pending',
        };
      }
      if (status === 1) {
        return {
          class: 'text-success',
          text: 'Confirmed',
        };
      }
      if (status === -1) {
        return {
          class: 'text-danger',
          text: 'Canceled',
        };
      }
      return {
        class: 'text-warning',
        text: 'Pending',
      };
    },
    onSearch(type) {
      if (type === 'reset') {
        this.search = {
          userID: '',
          userEmail: '',
          userF: '',
          page: 1,
        };
      }
      this.$store.dispatch('member/req_getHistoryWallet', this.search);
    },
    onChangePage(payload) {
      this.search.page = payload;
      if (this.typeHistory === 'Ctrader') {
        this.$store.dispatch('history/req_getHistoryWallet', this.search);
      }
      if (this.typeHistory === 'MT5') {
        this.$store.dispatch('history/req_getHistoryWalletMT5', { type: 'wallet', page: payload });
      }
    },
    getDateTime(unix) {
      return moment.unix(unix).utcOffset('+00:00').format('DD/MM/YYYY, HH:mm:ss');
    },
    getSymbol(id) {
      console.log(id);
      return filter(this.Coin, (elm) => elm.Currency_ID === id)[0].Currency_Name;
    },
    getAction(id) {
      return filter(this.Actions, (elm) => elm.MoneyAction_ID === id)[0];
    },
  },
  created() {
    this.$store.dispatch('history/req_getHistoryWallet', this.search);
    this.$store.dispatch('history/req_getHistoryWalletMT5', { type: 'wallet', page: 1 });
  },
};
</script>

<style lang="scss">
.wallet-history {
  .box-member {
    margin-bottom: 20px;
    border: none;
    -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    border-radius: 3px;
    background-color: #fff;
    min-height: 200px;
    width: 100%;
    .title {
      font-weight: 600;
      font-size: 20px;
      color: #2f394e;
      text-align: left;
      padding: 0 0 5px;
      text-transform: uppercase;
      width: 100%;
      padding: 5px 1.25rem;
    }
    .type-history {
      padding: 5px 1.25rem;
      select {
        width: 100%;
        max-width: 320px;
      }
    }
    .table-search {
      font-weight: 600;
      font-size: 20px;
      color: #2f394e;
      text-align: left;
      padding: 0 0 5px;
      border-top: 3px solid rgba($color: #000000, $alpha: 0.08);
      border-bottom: 3px solid rgba($color: #000000, $alpha: 0.08);
      text-transform: uppercase;
      width: 100%;
      min-height: 50px;
      padding: 5px 1.25rem;
      display: flex;
      flex-wrap: wrap;
      input {
        max-width: 225px;
      }
      select,
      input {
        border: 0;
        border-radius: 0;
        border-bottom: 2px solid #6c757d;
        height: 40px;
        font-size: clamp(15px, 1vw, 16.2px);
        font-weight: 600;
        position: relative;
        &:focus {
          box-shadow: none;
          border-bottom: 2px solid #097501;
        }
        &::placeholder {
          font-weight: 500;
          font-size: clamp(13px, 1vw, 15px);
        }
      }
      button {
        &.btn-egg {
          min-width: 105px;
          height: 40px;
        }
        width: auto;
        min-width: unset;
        font-weight: 600;
      }
    }
    .table-reponsive {
      width: calc(100% - 10px);
      overflow-x: auto;
      overflow-y: hidden;
      padding: 10px 5px;
      position: relative;
      box-sizing: content-box;
      table {
        width: 100%;
        border-radius: 10px;
        th,
        td {
          min-width: 100px;
          white-space: pre;
          padding: 5px 0.75rem;
        }
        thead {
          background: #FF5C00;
          border-radius: 10px;
          box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1), 0 1px 0 0 rgba(0, 0, 0, 0.1);
          border: 0;
          color: #fff;
          font-weight: 600;
          tr {
            border-radius: 10px;
            th {
              border: 0;
              &:last-child {
                border-radius: 0px 10px 10px 0px;
              }
              &:first-child {
                border-radius: 10px 0 0 10px;
              }
            }
          }
        }
        tbody:before {
          content: '@';
          display: block;
          line-height: 10px;
          text-indent: -99999px;
        }
      }
    }
    .table-paginate {
      border-top: 3px solid rgba($color: #000000, $alpha: 0.08);
    }
  }
}
</style>
